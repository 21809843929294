import { createApp } from 'vue/dist/vue.esm-bundler.js';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Card from 'primevue/card';
import Menu from 'primevue/menu';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import piniastore from '@/store/store.js';
import { createEtTooltipDirective } from '@/directives/ettooltip';
import '@/assets/styles.scss';
import 'material-symbols';

const app = createApp(App);
app.use(piniastore);
app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('ettooltip', createEtTooltipDirective());
app.component('DynamicDialog', DynamicDialog);
app.component('DataTable', DataTable);
app.component('InputText', InputText);
app.component('Column', Column);
app.component('Button', Button);
app.component('Badge', Badge);
app.component('Card', Card);
app.component('Menu', Menu);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
router.isReady().then(() => {
	app.mount('#app');
});
