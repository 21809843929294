// directives/ettooltip.js
import { ref } from 'vue';

export const createEtTooltipDirective = () => {
	return {
		mounted(el, binding) {
			const showTooltip = ref(false);
			const tooltipElement = ref(null);
			const isLoading = ref(false);
			const contentWrapper = ref(null);

			const createTooltipElement = (content, tooltip) => {
				contentWrapper.value.appendChild(document.createTextNode(content));
				tooltip.appendChild(contentWrapper.value);
				el.appendChild(tooltip);
				return tooltip;
			};

			const createLoadingIcon = () => {
				const tooltip = document.createElement('div');
				tooltip.classList.add('ettooltip');
				contentWrapper.value = document.createElement('div');
				contentWrapper.value.classList.add('ettooltip__content');
				const loadingIcon = document.createElement('i');
				loadingIcon.classList.add('pi', 'pi-spin', 'pi-spinner');
				contentWrapper.value.appendChild(loadingIcon);
				tooltip.appendChild(contentWrapper.value);
				el.appendChild(tooltip);
				return tooltip;
			};

			const removeLoadingIcon = () => {
				contentWrapper.value.removeChild(contentWrapper.value.lastChild);
			};

			const showTooltipHandler = async (event) => {
				event.stopPropagation();
				showTooltip.value = true;
				isLoading.value = true;
				const tooltip = createLoadingIcon();
				const content = await binding.value.fetchContent(el.dataset.tooltipParam);
				removeLoadingIcon();
				tooltipElement.value = createTooltipElement(content, tooltip);
				isLoading.value = false;
			};

			const hideTooltipHandler = (event) => {
				event.stopPropagation();
				showTooltip.value = false;
				if (tooltipElement.value) {
					el.removeChild(tooltipElement.value);
					tooltipElement.value = null;
				}
			};

			el.addEventListener('click', showTooltipHandler);
			el.addEventListener('mouseleave', hideTooltipHandler);

			const cleanup = () => {
				el.removeEventListener('click', showTooltipHandler);
				el.removeEventListener('mouseleave', hideTooltipHandler);
				if (tooltipElement.value) {
					el.removeChild(tooltipElement.value);
				}
			};

			el.classList.add('ettooltip-wrapper');
			el.classList.add('p-tooltip');
			el.classList.add('p-component');

			// Embed the CSS styles
			const style = document.createElement('style');
			style.textContent = `
                  .ettooltip-wrapper {
                    position: relative;
                    display: inline-block;
                  }
        
                  .ettooltip {
                    position: absolute;
                    z-index: 1;
                    background-color: #333;
                    opacity: 0.8;
                    color: #fff;
                    padding: 8px;
                    border-radius: 4px;
                    width: fit-content;
                    pointer-events: none;
                  }
        
                  .ettooltip--top {
                    bottom: 150%;
                  }
        
                  .ettooltip--bottom {
                    top: 150%;
                  }
        
                  .ettooltip__content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px 8px;
                  }
                `;
			document.head.appendChild(style);

			return {
				showTooltip,
				tooltipElement,
				isLoading,
				cleanup
			};
		}
	};
};
