import { createRouter, createWebHashHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const router = createRouter({
	history: createWebHashHistory(),

	routes: [
		{
			path: '/',
			meta: {
				requiresAuth: true
			},
			component: AppLayout,
			children: [
				{
					path: '/',
					name: 'dashboard',
					component: () => import('@/views/Dashboard.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/devices/list',
					name: 'devices',
					component: () => import('@/views/devices/List.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/devices/swap',
					name: 'swap',
					component: () => import('@/views/devices/Swap.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/devices/update',
					name: 'devicesupdate',
					component: () => import('@/views/devices/BulkUpdate.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/alerts/list',
					name: 'alerts',
					component: () => import('@/views/alerts/List.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/alerts/rules',
					name: 'rules',
					component: () => import('@/views/alerts/Rules.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/alerts/faultcodes',
					name: 'faultcodes',
					component: () => import('@/views/alerts/FaultCodes.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/alerts/controls',
					name: 'alertcontrols',
					component: () => import('@/views/alerts/Controls.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/list',
					name: 'lifts',
					component: () => import('@/views/lifts/List.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/dashboard',
					name: 'escalatorsdashboard',
					component: () => import('@/views/escalator/Dashboard.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/list',
					name: 'escalators',
					component: () => import('@/views/escalator/List.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/telemetry/:id',
					name: 'escalatorstelemetry',
					component: () => import('@/views/escalator/Events.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/analysis/:id',
					name: 'escalatorsanalysis',
					component: () => import('@/views/escalator/Analysis.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/alerts/:id',
					name: 'escalatorsalerts',
					component: () => import('@/views/escalator/Alerts.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/escalators/stats/:id',
					name: 'escalatorsstats',
					component: () => import('@/views/escalator/Stats.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/stats/:id',
					name: 'stats',
					component: () => import('@/views/lifts/LiftStats.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/triplog/:id',
					name: 'triplog',
					component: () => import('@/views/lifts/TripLog.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/idle/:id',
					name: 'idle',
					component: () => import('@/views/lifts/settings/Idle.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/twin/:id',
					name: 'twin',
					component: () => import('@/views/lifts/settings/Twin.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/history/:id',
					name: 'history',
					component: () => import('@/views/lifts/settings/History.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/reports/lifts',
					name: 'LiftReport',
					component: () => import('@/views/reports/LiftReport.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/device/:id',
					name: 'liftdevices',
					component: () => import('@/views/lifts/settings/Device.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/message/:id',
					name: 'liftmessage',
					component: () => import('@/views/lifts/settings/Message.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/lifts/settings/doortraining/:id',
					name: 'doortraining',
					component: () => import('@/views/lifts/settings/TelemetryVisualizer.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/settings/users',
					name: 'users',
					component: () => import('@/views/settings/users/List.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/settings/customers',
					name: 'customers',
					component: () => import('@/views/settings/customers/List.vue'),
					meta: {
						requiresAuth: true
					}
				}
			]
		},
		{
			path: '/pages/notfound',
			name: 'notfound',
			component: () => import('@/views/pages/NotFound.vue'),
			meta: {
				requiresAuth: false
			}
		},

		{
			path: '/auth/login',
			name: 'login',
			component: () => import('@/views/pages/auth/Login.vue'),
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/auth/access',
			name: 'accessDenied',
			component: () => import('@/views/pages/auth/Access.vue'),
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/auth/error',
			name: 'error',
			component: () => import('@/views/pages/auth/Error.vue'),
			meta: {
				requiresAuth: false
			}
		}
	]
});

router.beforeEach((to, from, next) => {
	const authRequired = to.matched.some((route) => route.meta.requiresAuth);
	const loggedIn = localStorage.getItem('user');
	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/auth/login');
	} else {
		next();
	}
});

export default router;
