<script setup>
import ScrollTop from 'primevue/scrolltop';
import ConfirmDialog from 'primevue/confirmdialog';
import { onMounted } from 'vue';
// import { webSocket, isConnected } from '@/service/SocketService';
import Toast from 'primevue/toast';
// import { useToast } from 'primevue/usetoast';

// const toast = useToast();

// watch(isConnected, (value) => {
// 	if (value) {
// 		webSocket.onmessage = function (event) {
// 			console.log('WS Received Message : ' + JSON.stringify(event, null, 4));
// 			toast.add({
// 				severity: 'info',
// 				summary: event.data.title,
// 				detail: event.data.message,
// 				life: 3000
// 			});
// 		};
// 	} else {
// 		toast.add({
// 			severity: 'error',
// 			summary: 'Disconnected',
// 			detail: 'Websocket is disconnected',
// 			life: 3000
// 		});
// 	}
// });

onMounted(() => {
	document.title = 'Lift Dashboard - Elevate Tech';
});
</script>

<template>
	<router-view />
	<ScrollTop
		:pt="{
			root: 'border-round-sm bg-primary',
			icon: {
				class: 'text-base'
			}
		}"
	/>
	<ConfirmDialog />
	<Toast />
</template>

<style>
@import url('./themes/mdc-light-deeppurple/theme.css');
</style>
