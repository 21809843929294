import axios from 'axios';

const API_URL = 'https://api-lift.elevate-tech.sg/user/';

class AuthService {
	login(user) {
		return axios.post(API_URL + 'login', user).then((response) => {
			if (response.data.token) {
				localStorage.setItem('user', JSON.stringify(response.data));
			}

			return response.data;
		});
	}

	refreshToken() {
		const user = JSON.parse(localStorage.getItem('user'));
		const refreshToken = user['refreshToken'];
		if (refreshToken === null) {
			localStorage.removeItem('user');
			return Promise.reject('No refresh token found');
		} else {
			return axios.post(API_URL + 'refresh', { refreshToken }).then((response) => {
				if (response.data.token) {
					const newData = { ...user, ...response.data };
					localStorage.setItem('user', JSON.stringify(newData));
				}

				return Promise.resolve(response.data);
			});
		}
	}

	logout() {
		localStorage.removeItem('user');
	}

	register(user) {
		return axios.post(API_URL + 'signup', {
			username: user.username,
			email: user.email,
			password: user.password
		});
	}
}

export default new AuthService();
