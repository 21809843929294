import AuthService from '../service/auth.service';
import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
import authHeader from '@/service/auth-header';
const user = JSON.parse(localStorage.getItem('user'));

export const authStore = defineStore('auth', {
	state: () => ({
		status: {
			loggedIn: user ? true : false
		},
		user: user ? user : null
	}),
	getters: {
		isLoggedIn(state) {
			return state.status.loggedIn;
		},
		getUser(state) {
			return state.user;
		}
	},
	actions: {
		login(user) {
			console.log(user);
			return AuthService.login(user).then(
				(user) => {
					this.status.loggedIn = true;
					this.user = user;
					return Promise.resolve(user);
				},
				(error) => {
					this.status.loggedIn = false;
					this.user = null;
					return Promise.reject(error);
				}
			);
		},
		refreshToken() {
			return AuthService.refreshToken().then(
				(user) => {
					this.status.loggedIn = true;
					this.user = { ...this.user, ...user };
					return Promise.resolve(user);
				},
				(error) => {
					console.log('refresh token error');
					this.status.loggedIn = false;
					this.user = null;
					AuthService.logout();
					return Promise.reject(error);
				}
			);
		},
		autoRefresh(context) {
			const { commit, dispatch } = context;
			const accessToken = authHeader();
			const { exp } = jwtDecode(accessToken);
			const now = Date.now() / 1000; // exp is represented in seconds since epoch
			let timeUntilRefresh = exp - now;
			timeUntilRefresh -= 2 * 60; // Refresh 5 minutes before it expires
			const refreshTask = setTimeout(() => dispatch('refreshToken'), timeUntilRefresh * 1000);
			commit('refreshTask', refreshTask); // In case you want to cancel this task on logout
		},
		logout() {
			AuthService.logout();
			this.status.loggedIn = false;
			this.user = null;
			// cancel refresh task
		}
	}
});
